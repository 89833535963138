<template>
  <div v-if="section">
    <b-tabs>
      <b-tab title="Settings" active>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex mb-3">
              <label class="mr-4">Enable</label>
              <b-form-checkbox v-model="section.IsEnable" switch></b-form-checkbox>
            </div>
          </div>
          <div class="col-md-12">
            <label>Position</label>
            <AlignInput v-model="section.Position" />
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Type</label>
              <select v-model="section.Type" class="form-control">
                <option value="text">Text only</option>
                <option value="icon-left">Text + icon left</option>
                <option value="icon-right">Text + icon right</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <IconInput v-model="section.Icon" label="Icon" />
          </div>
          <div class="col-md-10">
            <div class="form-group">
              <label>Label</label>
              <input v-model="section.Label" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Styling">
        <div class="row">
          <div class="col-md-6">
            <ColorInput v-model="section.BackgroundColor" label="Background" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="section.BorderColor" label="Border color" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="section.Color" label="Color" />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="section.FontSize" label="Font size" />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="section.Roundness" label="Roundness" />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="section.Padding" label="Padding" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ColorInput from '../../Coupons/Components/ColorInput'
import NumberInput from '../../Coupons/Components/NumberInput'
import IconInput from '../../Coupons/Components/IconInput'
import AlignInput from '../../Coupons/Components/AlignInput'

export default {
  components: {
    ColorInput,
    NumberInput,
    IconInput,
    AlignInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style></style>
